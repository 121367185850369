<template>
<div>
    <!-- 顶部时间选择 -->
    <van-row style="margin: 10px">
        <van-col span="5" class="custom-col">
            <!-- <van-datetime-picker v-model="currentTime" type="time" :min-hour="10" :max-hour="20" /> -->
            <van-cell style="display: flex; align-items: center;" v-model="begin_time" @click="changeTime(1)">
                <span style="display: flex; justify-content: center;font-size: 10px;">{{ begin_time }}</span></van-cell>
            <van-row style="display: flex;justify-content: center;padding-bottom: 5px;">
                <span style="height:4px;">|</span>
            </van-row>

            <van-cell style="display: flex; align-items: center;" v-model="end_time" @click="changeTime(2)"><span style="display: flex; justify-content: center;font-size: 10px;">{{end_time}}</span></van-cell>
            <van-row style="display: flex;justify-content: center;margin: 5px">
                <van-switch v-model="auto_bid" size="12" @change="changeBid" />
            </van-row>

        </van-col>
        <van-row style="padding: 5px;" type="flex" justify="space-around">
            <van-col span="10">
                <van-cell title="敬请期待" style="color:gray">
                    <template #right-icon>
                        <div style="display: flex; align-items: center;">
                            <van-icon name="plus" />
                        </div>

                    </template>
                </van-cell>
            </van-col>
            <van-col span="10">
                <van-cell title="连锁模式" @click="goLine">
                    <template #right-icon>
                        <div style="display: flex; align-items: center;">
                            <van-icon name="plus" color="blue" />
                        </div>

                    </template>
                </van-cell>
            </van-col>

        </van-row>
        <van-row style="padding: 5px;" type="flex" justify="space-around">
            <van-col span="10">
                <van-cell title="敬请期待" style="color:gray">
                    <template #right-icon>
                        <div style="display: flex; align-items: center;">
                            <van-icon name="plus" />
                        </div>

                    </template>
                </van-cell>
            </van-col>
            <van-col span="10">
                <van-cell title="自定义模式" @click="goCustom">
                    <template #right-icon>
                        <div style="display: flex; align-items: center;">
                            <van-icon name="plus" color="blue" />
                        </div>

                    </template>
                </van-cell>
            </van-col>

        </van-row>
        <!-- <van-col span="8"  style="display: flex;align-items: center;">
            <van-row span="8" class="custom-col">
              
            </van-row>
            <van-row span="8" class="custom-col">
              <van-radio v-model="mode" name="连锁模式">连锁模式</van-radio>
            </van-row>>
            
          </van-col>
          <van-col span="8" style="display: flex;align-items: center;">
            <van-radio v-model="mode" name="杂牌模式">杂牌模式</van-radio>
            <van-radio v-model="mode" name="连锁模式">连锁模式</van-radio>
          </van-col> -->
    </van-row>

    <!-- 模式选择 -->
    <van-row style="margin: 10px">
        <van-search style="width: 100%;" :placeholder="searchHolder[searchType]" v-model="searchText">
            <template #left-icon>
                <span class="custom-text">{{searchTitle[searchType]}}
                    <van-icon style="margin-left: 2px;" name="sort" @click="changeSearchType" /></span>
            </template>
            <template #right-icon>
                <span class="search-text-right" @click="onSearch">搜索</span>
            </template>
        </van-search>
    </van-row>

    <!-- 方案列表 -->
    <van-cell-group style="margin: 10px 10px 50px 10px;background-color: transparent;">
        <van-list v-model="loading" :error.sync="error" error-text="请求失败，点击重新加载" :offset="20" @load="onLoad">
            <div v-for="(config, index) in tableData" :key="index" style="padding: 5px; font-size: small;">
                <van-cell :title="config.name" :label="config.type_name" class="cell-with-margin">
                    <template #right-icon>
                        <van-icon name="new-o" size="18px" color="#1989fa" @click="copyConfig(config)" />
                        <van-switch style="margin-left: 16px;" size="12px" v-model="config.status === 1" @change="closeConfig(config)" />
                        <van-icon style="margin-left: 16px;" name="setting" color="pink" @click="editConfig(config)" />
                    </template>
                </van-cell>
                <van-row type="flex" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;" @click="bidDetail(config)">
                    <van-col span="6">近{{config.total_order}}单数据</van-col>
                    <van-col span="6">报价成功：<span style="color:blue">{{config.bid_order}}</span></van-col>
                    <van-col span="6">中标成功：<span style="color:green">{{config.success_order}}</span></van-col>
                    <van-col span="5" style="text-align: right;">
                        <van-icon style="margin-left: 2px;" name="search" />
                    </van-col>
                </van-row>
            </div>
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
        </van-list>
        <!-- <van-row v-for="(config, index) in tableData" :key="index" style="background-color: white;margin-bottom: 20px;border: 1ch solid rgb(255, 255, 255);">
            <van-cell :title="config.name" :label="config.type_name" class="cell-with-margin">
                <template #right-icon>
                    <van-icon name="new-o" size="18px" color="#1989fa" @click="copyConfig(config)" />
                    <van-switch style="margin-left: 16px;" size="12px" v-model="config.status === 1" @change="closeConfig(config)" />
                    <van-icon style="margin-left: 16px;" name="setting" color="pink" @click="editConfig(config)" />
                </template>
            </van-cell>
            <van-row type="flex" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;" @click="bidDetail(config)">
                <van-col span="6">近{{config.total_order}}单数据</van-col>
                <van-col span="6">报价成功：<span style="color:blue">{{config.bid_order}}</span></van-col>
                <van-col span="6">中标成功：<span style="color:green">{{config.success_order}}</span></van-col>
                <van-col span="5" style="text-align: right;">
                    <van-icon style="margin-left: 2px;" name="search" />
                </van-col>
            </van-row>
        </van-row> -->

        <!-- <van-row type="flex" justify="space-between" style="font-size: 4px;padding-left: 10px;padding-bottom: 10px;">
                  <van-col span="5">近40单数据</van-col>
                  <van-col span="5">已报价成功</van-col>
                  <van-col span="5">已中标成功</van-col>
                   <van-col span="5"><van-icon style="margin-left: 2px;" name="search" /></van-col> 
                </van-row> -->

    </van-cell-group>
    <van-popup v-model="showBottom" position="bottom">
        <van-datetime-picker v-model="currentTime" type="time" :min-hour="0" :max-hour="23" @confirm="handleConfirm" @cancel="showBottom = false" />
    </van-popup>

</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
import Api from '@/api-service'
import {
    Icon
} from 'vant';

export default {
    components: {
        'van-icon': Icon
    },
    name: "Rule-Detail",
    data() {
        return {
            tableData: [],
            auto_bid: true,

            currentTime: '',
            showBottom: false,
            src: 0,

            begin_time: '00:01',
            end_time: '23:59',

            searchType: 1,
            // 搜索
            searchTitle: {
                1: '影院',
                2: '方案'
            },
            searchHolder: {
                1: '请输入影院名',
                2: '请输入方案名'
            },
            searchText: '', // 搜索内容

            page: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            error: false,

        }
    },
    created() {
        let data = {
            page: this.page,
            pageSize: this.pageSize,
        }
        this.fetchData(data);
        this.auto_bid = this.userInfo.auto == 1
        if (this.userInfo.bid_time.length > 0) {
            // 分割字符串

            let arr = this.userInfo.bid_time.split("-"); // 使用 String.prototype.split 方法
            if (arr.length === 2) {
                this.begin_time = arr[0]; // 分割后的第一部分为开始时间
                this.end_time = arr[1]; // 分割后的第二部分为结束时间
            }
        }
    },
    computed: {
        ...mapGetters(['hasGetUserInfo']),
        userInfo() {
            return this.$store.state.user.userInfo;
        },
    },
    methods: {
        fetchData(data) {
            Api.getCinemaRuleList(data).then(res => {
                if (res.page == this.page) {
                    if (this.page == 1) {
                        this.tableData = res.list;
                        this.total = res.total;
                    }else {
                        this.tableData = this.tableData.concat(res.list);
                    }
                    this.page++;

                }

                // console.log(this.tableData)
            })
        },
        getSetting() {

        },
        setStatus(item) {
            let data = {
                id: item.id,
                status: item.status == 1 ? 2 : 1
            }

            Api.setCinemaRuleStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '操作成功'
                });
                this.fetchData();
            })
        },
        goBack() {
            this.$router.go(-1);
        },
        status(item) {
            return item.status == 1 ? true : false;
        },
        changeTime(from) {
            console.log(from)
            this.showBottom = true;
            this.src = from;
            if (from == 1) {
                this.currentTime = this.begin_time;
            } else {
                this.currentTime = this.end_time;
            }
        },
        handleConfirm(value) {
            console.log(value);
            if (this.src == 1) {
                this.begin_time = value;
            } else {
                this.end_time = value;
            }
            let data = {
                bid_time: this.begin_time + '-' + this.end_time
            }

            Api.setAutoStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '设置成功'
                });

                console.log(this.userInfo)

            })
            this.showBottom = false;
        },
        goCustom() {
            this.$router.push({
                path: '/rule/custom',
                query: {
                    type: 4,
                }
            });
        },
        goLine() {
            this.$router.push({
                path: '/rule/line',
                query: {
                    type: 2,
                }
            });
        },
        editConfig(config) {
            let path = '/rule/custom'
            if (config.type == 2) {
                path = '/rule/line'
            }
            this.$router.push({
                path: path,
                query: {
                    id: config.id,
                    type: config.type,
                }
            })
        },
        copyConfig(config) {
            // 复制报价
            let path = '/rule/custom'
            if (config.type == 2) {
                path = '/rule/line'
            }
            this.$router.push({
                path: path,
                query: {
                    id: config.id,
                    type: config.type,
                    copy: 1,
                }
            })
        },
        closeConfig(config, checked) {
            // 关闭报价
            let data = {
                id: config.id,
                status: config.status == 1 ? 2 : 1
            }
            Api.updateConfigStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '操作成功'
                });
                this.fetchData();
            })
        },
        changeSearchType() {
            if (this.searchType == 1) {
                this.searchType = 2
            } else {
                this.searchType = 1
            }
        },
        onSearch() {
            this.page = 1;
            this.pageSize = 10;
            let data = {
                page: this.page,
                pageSize: this.pageSize,
            }
            if (this.searchText.length > 0) {
                switch (this.searchType) {
                    case 1:
                        data.cinema_name = this.searchText
                        break;
                    case 2:
                        data.name = this.searchText
                        break;
                }
            }
            Api.getCinemaRuleList(data).then(res => {
                this.tableData = res.list;
                // console.log(this.tableData)
            })
        },
        changeBid(checked) {
            let data = {
                bid_type: this.auto_bid ? 1 : 2
            }

            Api.setAutoStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '操作成功'
                });

                console.log(this.userInfo)

            })
        },
        bidDetail(config) {
            this.$router.push({
                path: "/cinema/rule",
                query: {
                    id: config.id,
                    name: config.name
                }
            })
        },
        onLoad() {
            console.log('onLoad')
            // if (this.page < this.total / this.pageSize){
            //     this.page++
            // }

            let data = {
                page: this.page,
                pageSize: this.pageSize,
            }
            this.fetchData(data);
            this.loading = false
        },
    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 16px;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}

.custom-col {
    border: 1px solid #ccc;
    /* 边框颜色和宽度 */
    border-radius: 8px;
    /* 圆角大小 */
    background-color: white;
    /* 背景色 */
    // display: flex;
    // align-items: center;

    padding: 5px;
    /* 内边距，根据需要调整 */
}

.custom-model {
    border: 1px solid #ccc;
    /* 边框颜色和宽度 */
    border-radius: 8px;
    /* 圆角大小 */
    background-color: #f5f5f5;
    /* 背景色 */
    // display: flex;
    // align-items: center;

    // padding: 5px; /* 内边距，根据需要调整 */
}

/* 调整 van-cell 的高度 */
.custom-col .van-cell {
    height: 30px;
    /* 设置高度，根据需要调整 */
    font-size: 1px;
    // 字体
    /* 调整字体大小，根据需要调整 */
    // background-color: transparent;
}

.elliptical-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    /* 根据需要调整宽度 */
    height: 24px;
    /* 根据需要调整高度 */
    background-color: #f0f0f0;
    /* 背景色 */
    border-radius: 50%;
    /* 椭圆效果 */
    cursor: pointer;
}

/* 覆盖默认的左右图标样式 */
.van-search__left-icon .van-icon,
.van-search__right-icon .van-icon {
    background-color: transparent;
    /* 移除默认图标的背景色 */
    color: inherit;
    /* 继承文字颜色 */
    font-size: 16px;
    /* 根据需要调整字体大小 */
}

.custom-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    /* 根据需要调整宽度 */
    height: 100%;
    /* 使高度与搜索框一致 */
    background-color: #f0f0f0;
    /* 背景色 */
    border-radius: 30%;
    /* 椭圆效果 */
    cursor: pointer;
    font-size: 12px;
    /* 根据需要调整字体大小 */
    color: #333;
    /* 文字颜色 */
    user-select: none;
    /* 防止文本被选中 */
}

.search-text-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    /* 根据需要调整宽度 */
    height: 100%;
    /* 使高度与搜索框一致 */
    background-color: #f0f0f0;
    /* 背景色 */
    border-radius: 30%;
    /* 椭圆效果 */
    cursor: pointer;
    font-size: 12px;
    /* 根据需要调整字体大小 */
    color: red;
    /* 文字颜色 */
    user-select: none;
    /* 防止文本被选中 */
}

.cell-with-margin {
    display: flex;
    // align-items: center;
    /* 根据需要调整间隔大小 */
}
</style>
